
export default {
  name: 'CategoryFiltersMobile',
  components: {
    IconClose: () => import('@/assets/icons/close_icon.svg'),
    IconFilterBlue: () => import('@/assets/icons/icon-filters-blue.svg'),
    IconItemFilter: () => import('@/assets/icons/icon-item-filter-mobile.svg')
  },
  props: {
    filters: {
      type: Object,
      default() {}
    }
  },
  data: () => ({
    subFilterMobileOpen: null,
    show: '',
    qtdFilter: 0,
    isOpenFilter: false,
    submenuFilter: '',
    firstClick: true,
    btnFilterActive: false,
    loadFilters: false,
    filterSelectedforClick: '',
    filterAvailable: [
      { categoria: [] },
      { destaque: [] },
      { fabricante: [] },
      { marca: [] },
      { tipo_acessorio: [] },
      { tipo_solucao: [] },
      { descarte: [] },
      { indicacao: [] },
      { tipo_lentes: [] },
      { material: [] },
      { preco: [] }
    ],
    Urls: {
      categoria: '',
      destaque: '',
      fabricante: '',
      marca: '',
      descarte: '',
      indicacao: '',
      material: '',
      preco: '',
      tipo_lentes: '',
      tipo_acessorio: '',
      tipo_solucao: ''
    },
    showApplyed: false,
    filteredLetter: [],
    marcaSelected: '',
  }),
  mounted() {
    for (const filter of this.filters.selected) {
      if (filter.label === 'Categoria') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[0].categoria.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemCategoria = {}
          this.filterAvailable[0].categoria = this.filterAvailable[0].categoria.filter(item => !removeDuplicatedItemCategoria[item.label] && (removeDuplicatedItemCategoria[item.label] = true))
        }
      }
      if (filter.label === 'Destaques') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[1].destaque.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemDestaque = {}
          this.filterAvailable[1].destaque = this.filterAvailable[1].destaque.filter(item => !removeDuplicatedItemDestaque[item.label] && (removeDuplicatedItemDestaque[item.label] = true))
        }
      }
      if (filter.label === 'Fabricante') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[2].fabricante.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemFabricante = {}
          this.filterAvailable[2].fabricante = this.filterAvailable[2].fabricante.filter(item => !removeDuplicatedItemFabricante[item.label] && (removeDuplicatedItemFabricante[item.label] = true))
        }
      }
      if (filter.label === 'Marca') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[3].marca.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemMarca = {}
          this.filterAvailable[3].marca = this.filterAvailable[3].marca.filter(item => !removeDuplicatedItemMarca[item.label] && (removeDuplicatedItemMarca[item.label] = true))
        }
      }
      if (filter.label === 'Tipo de Acessório') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[4].tipo_acessorio.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemTipoAcessorio = {}
          this.filterAvailable[4].tipo_acessorio = this.filterAvailable[4].tipo_acessorio.filter(item => !removeDuplicatedItemTipoAcessorio[item.label] && (removeDuplicatedItemTipoAcessorio[item.label] = true))
        }
      }
      if (filter.label === 'Tipo de Solução') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[5].tipo_solucao.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemTipoSolucao = {}
          this.filterAvailable[5].tipo_solucao = this.filterAvailable[5].tipo_solucao.filter(item => !removeDuplicatedItemTipoSolucao[item.label] && (removeDuplicatedItemTipoSolucao[item.label] = true))
        }
      }
      if (filter.label === 'Descarte') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[6].descarte.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemDescarte = {}
          this.filterAvailable[6].descarte = this.filterAvailable[6].descarte.filter(item => !removeDuplicatedItemDescarte[item.label] && (removeDuplicatedItemDescarte[item.label] = true))
        }
      }
      if (filter.label === 'Indicação') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[7].indicacao.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace('grau', '').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemIndicacao = {}
          this.filterAvailable[7].indicacao = this.filterAvailable[7].indicacao.filter(item => !removeDuplicatedItemIndicacao[item.label] && (removeDuplicatedItemIndicacao[item.label] = true))
        }
      }
      if (filter.label === 'Tipo de Lente') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[8].tipo_lentes.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemTipoLentes = {}
          this.filterAvailable[8].tipo_lentes = this.filterAvailable[8].tipo_lentes.filter(item => !removeDuplicatedItemTipoLentes[item.label] && (removeDuplicatedItemTipoLentes[item.label] = true))
        }
      }
      if (filter.label === 'Material') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[9].material.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemMaterial = {}
          this.filterAvailable[9].material = this.filterAvailable[9].material.filter(item => !removeDuplicatedItemMaterial[item.label] && (removeDuplicatedItemMaterial[item.label] = true))
        }
      }
      if (filter.label === 'Preço') {
        for (const item of filter.options) {
          this.qtdFilter += 1
          this.filterAvailable[10].preco.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
          const removeDuplicatedItemPreco = {}
          this.filterAvailable[10].preco = this.filterAvailable[10].preco.filter(item => !removeDuplicatedItemPreco[item.label] && (removeDuplicatedItemPreco[item.label] = true))
        }
      }
      this.mountedUrlFilter()
    }
  },
  methods: {
    OpenFilter() {
      this.isOpenFilter = !this.isOpenFilter
    },
    CloseFilter() {
      this.isOpenFilter = !this.isOpenFilter
      this.submenuFilter = ''
    },
    openSubMenuFilter(number) {
      if (this.subFilterMobileOpen === number) {
        this.subFilterMobileOpen = null
      } else {
        this.subFilterMobileOpen = number
      }
    },
    filterClicked(group, item) {
      this.showApplyed = true
      if (group === 'Categoria') {
        if (item.selected === true) {
          this.filterAvailable[0].categoria.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[0].categoria.filter(element => element.url !== item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''))
          this.filterAvailable[0].categoria = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[0].categoria = this.filterAvailable[0].categoria.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      if (group === 'Destaques') {
        if (item.selected === true) {
          this.filterAvailable[1].destaque.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[1].destaque.filter(element => element.url !== item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''))
          this.filterAvailable[1].destaque = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[1].destaque = this.filterAvailable[1].destaque.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      if (group === 'Fabricante') {
        if (item.selected === true) {
          this.filterAvailable[2].fabricante.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[2].fabricante.filter(element => element.url !== item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''))
          this.filterAvailable[2].fabricante = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[2].fabricante = this.filterAvailable[2].fabricante.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      if (group === 'Marca') {
        if (item.selected === true) {
          this.filterAvailable[3].marca.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[3].marca.filter(element => element.url !== item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''))
          this.filterAvailable[3].marca = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[3].marca = this.filterAvailable[3].marca.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      if (group === 'Tipo de Acessório') {
        if (item.selected === true) {
          this.filterAvailable[4].tipo_acessorio.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[4].tipo_acessorio.filter(element => element.url !== item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''))
          this.filterAvailable[4].tipo_acessorio = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[4].tipo_acessorio = this.filterAvailable[4].tipo_acessorio.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      if (group === 'Tipo de Solução') {
        if (item.selected === true) {
          this.filterAvailable[5].tipo_solucao.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[5].tipo_solucao.filter(element => element.url !== item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''))
          this.filterAvailable[5].tipo_solucao = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[5].tipo_solucao = this.filterAvailable[5].tipo_solucao.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      if (group === 'Descarte') {
        if (item.selected === true) {
          this.filterAvailable[6].descarte.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[6].descarte.filter(element => element.url !== item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''))
          this.filterAvailable[6].descarte = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[6].descarte = this.filterAvailable[6].descarte.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      if (group === 'Indicação') {
        if (item.selected === true) {
          this.filterAvailable[7].indicacao.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace('grau', '').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[7].indicacao.filter(element => element.url !== item.label.toString().toLowerCase().normalize('NFD').replace('grau', '').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''))
          this.filterAvailable[7].indicacao = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[7].indicacao = this.filterAvailable[7].indicacao.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      if (group === 'Tipo de Lente') {
        if (item.selected === true) {
          this.filterAvailable[8].tipo_lentes.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[8].tipo_lentes.filter(element => element.url !== item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''))
          this.filterAvailable[8].tipo_lentes = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[8].tipo_lentes = this.filterAvailable[8].tipo_lentes.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      if (group === 'Material') {
        if (item.selected === true) {
          this.filterAvailable[9].material.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[9].material.filter(element => element.url !== item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, ''))
          this.filterAvailable[9].material = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[9].material = this.filterAvailable[9].material.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      if (group === 'Preço') {
        if (item.selected === true) {
          this.filterAvailable[10].preco.push({ url: item.label.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/([^\w]+|\s+)/g, '-').replace(/(^-+|-+$)/, '').replace('-', '').replace('de', '').replace('r', '').replace('-', '').replace(' ', '').replace('a', '').replace('-', '').replace('r', '').replace('-', ''), label: item.label })
        } else {
          const filtersList = this.filterAvailable[10].preco.filter(element => element.url !== item.label)
          this.filterAvailable[10].preco = filtersList
        }
        const removeDuplicatedItem = {}
        this.filterAvailable[10].preco = this.filterAvailable[10].preco.filter(item => !removeDuplicatedItem[item.label] && (removeDuplicatedItem[item.label] = true))
      }
      this.mountedUrlFilter()
    },
    mountedUrlFilter() {
      this.filterSelectedforClick = ''
      this.Urls.categoria = ''
      this.Urls.destaque = ''
      this.Urls.fabricante = ''
      this.Urls.marca = ''
      this.Urls.descarte = ''
      this.Urls.indicacao = ''
      this.Urls.material = ''
      this.Urls.preco = ''
      this.Urls.tipo_lentes = ''
      this.Urls.tipo_acessorio = ''
      this.Urls.tipo_solucao = ''
      if (this.filterAvailable[0].categoria.length > 0) {
        for (const item of this.filterAvailable[0].categoria) {
          this.Urls.categoria += '--' + item.url
        }
      }
      if (this.filterAvailable[1].destaque.length > 0) {
        for (const item of this.filterAvailable[1].destaque) {
          this.Urls.destaque += '--' + item.url
        }
      }
      if (this.filterAvailable[2].fabricante.length > 0) {
        for (const item of this.filterAvailable[2].fabricante) {
          this.Urls.fabricante += '--' + item.url
        }
      }
      if (this.filterAvailable[3].marca.length > 0) {
        for (const item of this.filterAvailable[3].marca) {
          this.Urls.marca += '--' + item.url
        }
      }
      if (this.filterAvailable[4].tipo_acessorio.length > 0) {
        for (const item of this.filterAvailable[4].tipo_acessorio) {
          this.Urls.tipo_acessorio += '--' + item.url
        }
      }
      if (this.filterAvailable[5].tipo_solucao.length > 0) {
        for (const item of this.filterAvailable[5].tipo_solucao) {
          this.Urls.tipo_solucao += '--' + item.url
        }
      }
      if (this.filterAvailable[6].descarte.length > 0) {
        for (const item of this.filterAvailable[6].descarte) {
          this.Urls.descarte += '--' + item.url
        }
      }
      if (this.filterAvailable[7].indicacao.length > 0) {
        for (const item of this.filterAvailable[7].indicacao) {
          this.Urls.indicacao += '--' + item.url
        }
      }
      if (this.filterAvailable[8].tipo_lentes.length > 0) {
        for (const item of this.filterAvailable[8].tipo_lentes) {
          this.Urls.tipo_lentes += '--' + item.url
        }
      }
      if (this.filterAvailable[9].material.length > 0) {
        for (const item of this.filterAvailable[9].material) {
          this.Urls.material += '--' + item.url
        }
      }
       if (this.filterAvailable[10].preco.length > 0) {
        for (const item of this.filterAvailable[10].preco) {
          this.Urls.preco += '--' + item.url
        }
      }
      this.Urls.tipo_acessorio = ''
      this.Urls.tipo_solucao = ''

      if (this.Urls.categoria !== '') {
        this.filterSelectedforClick += '/' + this.Urls.categoria.replace('--', '')
      }
      if (this.Urls.destaque !== '') {
        this.filterSelectedforClick += '/' + this.Urls.destaque.replace('--', '')
      }
      if (this.Urls.fabricante !== '') {
        this.filterSelectedforClick += '/' + this.Urls.fabricante.replace('--', '')
      }
      if (this.Urls.marca !== '') {
        this.filterSelectedforClick += '/' + this.Urls.marca.replace('--', '')
      }
      if (this.Urls.descarte !== '') {
        this.filterSelectedforClick += '/' + this.Urls.descarte.replace('--', '')
      }
      if (this.Urls.indicacao !== '') {
        this.filterSelectedforClick += '/' + this.Urls.indicacao.replace('--', '')
      }
      if (this.Urls.tipo_lentes !== '') {
        this.filterSelectedforClick += '/' + this.Urls.tipo_lentes.replace('--', '')
      }
      if (this.Urls.material !== '') {
        this.filterSelectedforClick += '/' + this.Urls.material.replace('--', '')
      }
      if (this.Urls.preco !== '') {
        this.filterSelectedforClick += '/' + this.Urls.preco.replace('--', '')
      }
      if (this.Urls.tipo_acessorio !== '') {
        this.filterSelectedforClick += '/' + this.Urls.tipo_acessorio.replace('--', '')
      }
      if (this.Urls.tipo_solucao !== '') {
        this.filterSelectedforClick += '/' + this.Urls.tipo_solucao.replace('--', '')
      }
      if (this.$route.query.q) {
        this.filterSelectedforClick = '/search' + this.filterSelectedforClick + '?q=' + this.$route.query.q
      }
      if (this.filterSelectedforClick === '') {
        this.showApplyed = false
      }
    }
  }
}
